<template>
  <div class="storie">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist desk" uk-sticky="bottom: #footer">
        <h3 class="sect">Gli eventi</h3>
      </div>

      <div class="img-main img-rist mobi">
        <h3 class="sect">Gli eventi</h3>
      </div>

      <div class="center persone" v-if="loading">
        <h1 class="title">{{ storia.titolo }}</h1>

        <div class="block">
          <p class="intro-c">
            {{ storia.testo }}
          </p>
        </div>

        <div class="imgCont" v-if="loading">
          <img :src="img" alt="" class="gallery" @click="changeImg" />
          <img
            v-if="storia.img.length > 1"
            class="arrow arr__dx"
            src="../assets/img/arr_dx.png"
            alt=""
            @click="changeImg"
          />
          <img
            v-if="storia.img.length > 1"
            class="arrow arr__sx"
            src="../assets/img/arr_sx.png"
            alt=""
            @click="changeImgBack"
          />
        </div>
        <div v-if="storia.img.length > 1">
          <div class="nimg">
            <span v-for="(n, index) in storia.img.length" :key="index"
              ><span v-if="index > 0"> | </span
              ><span
                class="num"
                @click="select(index)"
                v-bind:style="
                  index === count
                    ? 'text-decoration:underline'
                    : 'text-decoration:inherit'
                "
                >{{ index + 1 }}</span
              ></span
            >
          </div>
        </div>
        <div class="changePg__container">
          <router-link
            v-if="index > 0"
            :to="{ name: 'Eventi', params: { index: index_prev } }"
            class="changePg__butt changePg__butt--left"
            >{{ prev }}</router-link
          >
          <router-link
            v-else
            :to="{ name: 'storia', params: { index: index_prev } }"
            class="changePg__butt changePg__butt--left"
            >{{ prev }}</router-link
          >
          <router-link
            v-if="index < tot"
            :to="{ name: 'Eventi', params: { index: index_post } }"
            class="changePg__butt changePg__butt--right"
            >{{ next }}</router-link
          >
          <router-link
            v-else
            :to="{ name: 'Progetti', params: { index: index_post } }"
            class="changePg__butt changePg__butt--right"
            >{{ next }}</router-link
          >
        </div>
      </div>
      <div class="fdp"></div>
    </div>

    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
const axios = require("axios");

export default {
  name: "Eventi",
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      persone: null,
      storia: null,
      loading: false,
      next: null,
      prev: null,

      count: 0,
      img: "",
      index_post: null,
      index_prev: null,
      tot: null,
    };
  },
  methods: {
    select(n) {
      this.count = n;
      this.img = this.storia.img[this.count];
    },
    changeImg() {
      if (this.count < this.storia.img.length - 1) {
        this.count++;
        this.img = this.storia.img[this.count];
      } else {
        this.img = this.storia.img[0];
        this.count = 0;
      }
    },
    changeImgBack() {
      if (this.count > 0) {
        this.count--;
        this.img = this.storia.img[this.count];
      } else {
        this.img = this.storia.img[this.storia.img.length - 1];
        this.count = this.storia.img.length - 1;
      }
    },

    loadContent(cat, index) {
      this.loading = false;
      axios
        .get("https://spilleria.frb.io/storie.json")
        .then((response) => {
          this.persone = response.data[cat];
          this.storia = this.persone[index];
          this.img = this.storia.img[0];
          this.next =
            parseInt(index) === response.data[cat].length - 1
              ? response.data[cat + 1][0].titolo
              : response.data[cat][parseInt(index) + 1].titolo;
          this.prev =
            parseInt(index) === 0
              ? response.data[cat - 1][response.data[cat - 1].length - 1].titolo
              : response.data[cat][parseInt(index) - 1].titolo;
          this.index = parseInt(index);
          this.index_post =
            parseInt(index) === response.data[cat].length - 1
              ? 0
              : parseInt(index) + 1;
          this.index_prev =
            parseInt(index) === 0
              ? response.data[cat - 1].length - 1
              : parseInt(index) - 1;
          this.tot = response.data[cat].length - 1;
          this.loading = true;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
  },
  mounted() {
    this.loadContent(1, this.$route.params.index)
  },
  watch: {
    "$route.params.index": function () {
      this.loadContent(1, this.$route.params.index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: 1;
  min-height: 100vh;
}

.changePg__container {
  display: flex;
  margin-top: 150px;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1080px) {
    margin-top: 70px;
  }
}

.changePg__butt {
  width: 30%;
  color: $color__text-main;

  @media screen and (max-width: 1080px) {
    font-size: 13px;
    width: 40%;
  }
}

.changePg__butt--right {
  text-align: right;
  margin-right: 15px;
}
.changePg__butt--left {
  margin-left: 15px;
}

.imgCont {
  // margin-top: 15%;
  // border: 1px solid red;
  height: 40vw;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.gallery {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  width: auto;
  align-self: center;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 45%;
  cursor: pointer;

  &:hover {
    filter: brightness(1000%);
  }
  @media screen and (max-width: 1080px) {
    width: 10%;
  }
}

.arr__dx {
  right: 0;
}
.arr__sx {
  left: 0;
}

.nimg {
  text-align: center;
  font-size: 1.3em;
  margin-top: 1.3em;
}

.num {
  cursor: pointer;
}

.num:hover {
  text-decoration: underline;
}

.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  border-right: 1px solid $color__text-main;
}

.hero-img {
  height: 100vh;
}

.center {
  padding: 10vw 0;
  font-family: $font__stack-primary;
  color: $color__text-main;
}

.persone {
  grid-row: 1;
  grid-column: 2;
}

.eventi {
  grid-row: 2;
  grid-column: 2;
}

.progetti {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1 / end;
  grid-column: 1;
}

// .img-pizz {
//   grid-row: 2;
//   grid-column: 1;
// }

// .img-fornit {
//   grid-row: 3;
//   grid-column: 1;
// }

h1 {
  font-size: 4vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: $color__text-main;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 10vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 0.4vw;
  font-size: 1.2vw;
  display: block;
  max-width: 70%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 1.5vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vwpx;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}

.article {
  margin-top: 1.5vw;
  font-size: 2.2vw;
  line-height: 123%;
  border-bottom: 2px solid $color__text-main;
  color: $color__text-main;
  text-decoration: none;
  padding-bottom: 0.5vw;
  display: block;
}

.title {
  margin-bottom: 5vw;
  padding: 0 5vw;
}

.block {
  padding: 0 5vw;
  margin-bottom: 3vw;
}
.mobi {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
  }

  .mobi {
    display: block;
  }
  .desk {
    display: none;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    //background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 9vh;
  }
  .img-main {
    height: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }

  .article {
    font-size: 4vw;
    line-height: 123%;
    border-bottom: 1px solid $color__text-main;

    padding-bottom: 0.5vw;
  }

  .title {
    margin-bottom: 5vw;
  }

  .block {
    margin-bottom: 8vw;
  }

  .intro-c {
    font-size: 14px;
  }

  .imgCont {
    //  margin-top: 15%;
    //border: 1px solid red;

    height: 30vh;

    display: flex;
  }

  .nimg {
    text-align: center;
    font-size: 1em;
    margin-top: 1em;
  }
}
.img-mob {
  margin-bottom: 5vh;
}
</style>
