<template>
  <div class="contatti">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="title start">Dove siamo</div>

      <div class="info start desk">
        <a href="https://goo.gl/maps/euyyjRQ5n6cddKv1A"
          >Largo Cooperativa, 4 Sant'Agata Martesana frazione di Cassina de'
          Pecchi Milano (Italy)</a
        >
      </div>
      <div class="info start mob">
        <a href="https://goo.gl/maps/euyyjRQ5n6cddKv1A"
          >Largo Cooperativa, 4 Sant'Agata Martesana frazione di Cassina de'
          Pecchi</a
        >
        Milano (Italy)
      </div>
      <div></div>
      <div class="title">Come raggiungerci</div>
      <div class="info">
        <p>
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> In macchina: trovate sempre parcheggio
        </p>
        <p>
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> In bici: pedalate lungo il Naviglio Martesana,
          uscite dalla ciclabile a Sant’Agata, tutta dritta, la Spilleria è al
          centro del paese davanti al campetto da basket ed è attrezzata di un
          pit-stop per sistemare le bici!
        </p>
        <p>
          <span v-if="!isChrome">&#10132;</span><span v-else>→</span> Coi mezzi:
          prendete la linea verde MM2 direzione Gessate, scendete alla Fermata
          Villa Pompea e poi sono 10 minuti a piedi
        </p>
      </div>
      <div></div><div></div><div></div>
      <!-- <div class="title" style="text-decoration:underline">
        Per tutto il mese di maggio la Spilleria è operativa solo per asporto e
        consegna a domicilio.
      </div>
      <div class="info">
        Tutti i giorni da lunedì a domenica, dalle 19:30 – 21:30. <br />
        Consegnamo a Cassina de’ Pecchi, Vignate, Bussero, Gorgonzola, Pessano,
        Melzo, Cernusco sul Naviglio.<br />
        Consegna gratuita, ordine minimo 15€. <br />
        Tutti i nostri packaging sono biodegradabili in Mater-Bi <br />
        Per prenotazioni di asporto e consegna domicilio chiamare al
        <a href="tel:0291640682">02 91 64 06 82</a> dalle ore 18.00 o scrivere
        un messaggio Whatsapp al
        <a href="tel:+393662338461">+39 366 233 8461</a>.<br />
        Menu bere <router-link to="/birre">qui</router-link>, menù mangiare
        <router-link to="/cucina">qui</router-link>.
      </div> -->
      <div></div>
      <div class="title">Tel</div>
      <div class="info"><a href="tel:0291640682">02 91 64 06 82</a> <br class="mob">
      (solo dalle 18 in poi)<br> <br class="mob">
      <a href="tel:+39 3662338461 ">+39 3662338461</a> <br class="mob">
      (solo Whatsapp)
      </div>
      <div></div>
      <div class="title">Email</div>
      <div class="info">
        <a href="mailto:info@laspilleria.it">info@laspilleria.it</a><br />
        (non per prenotazioni)
        <br /><br />
        Per cene speciali, eventi, progetti, storie
        <a href="mailto:mandellifederica@gmail.com"
          >mandellifederica@gmail.com</a
        >
      </div>
      <div></div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";

export default {
  name: "Contatti",
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      isChrome: ""
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  min-height: 100vh;
  color: $color__text-main;
  font-family: $font__stack-primary;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.start {
  padding-top: 10vw;
}

.title {
  font-size: 3vw;
  text-align: right;
  margin-right: 2vw;
}

.info {
  padding-left: 2vw;
  padding-right: 2vw;
  font-size: 2vw;
  padding-bottom: 5vw;
  line-height: 150%;
}

.info > p {
  margin-top: 0;
}

.map {
  margin-top: 5vw;
  height: 20vw;
  border: 2px solid $color__text-main;
}

span {
  text-indent: 2vw;
}

a {
  color: $color__text-main;
  text-decoration: underline;
}

.mob {
  display: none;
}

@media screen and (max-width: 1080px) {
 
  .desk {
    display: none;
  }
  .mob {
    display: block;
  }
  .start {
    padding-top: 15vw;
  }

  .info {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 3vw;
    padding-bottom: 5vw;
    line-height: 150%;
  }
}
</style>
