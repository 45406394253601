<template>
  <div class="birre">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist" uk-sticky="bottom: #img-pizz">
        <img class="hero-img" src="@/assets/img/birrificio.jpg" />
        <h3 class="sect">Birrificio</h3>
      </div>

      <div class="img-main img-pizz" id="img-pizz" uk-sticky="bottom: #footer">
        <img class="hero-img" src="@/assets/img/brewpub-vert.jpg" />
        <h3 class="sect">Brew-pub</h3>
      </div>

      <div v-show="loading" class="center rist">
        <h1 class="title">Le birre</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/birrificio-m.jpg" />
        </div>
        <p class="cosi">
          Birra artigianale prodotta proprio qui. Dal 2012!
          <br/>
          <br />0.2cl 3.00€ <br />0.4cl 5.50€ <br />1lt 11.00€ <br /><br/>Birre ospiti in rotazione del Birrificio Manerba. Tra cui sempre presente la Hell Gluten
          Free Birrificio Manerba da 0.33cl
          <br />
        </p>

        <div v-for="item in birre" :key="item.length">
          <div class="gib-cont">
            <img class="gib" :src="item.gibollo" />
          </div>
          <div class="list">
            <div class="color" :style="'background:' + item.colore"></div>
            <div class="plate">
              {{ item.nome }}
              <br />
              {{ item.tipologia }}
            </div>
            <div class="line"></div>
            <div class="price">{{ item.gradazione }}%vol</div>
          </div>
          <div
            v-if="item.lattina === 1"
            class="price"
            style="padding-left:50%; margin-top: 0.5rem"
          >
            anche in lattina da 33cl
          </div>
          <div
            v-if="item.lattina === 2"
            class="price"
            style="padding-left:50%; margin-top: 0.5rem"
          >
            <span style="text-decoration:underline">solo</span> in lattina da
            33cl
          </div>
          <!-- <div class="ingr">
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.fermentazione}}<br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.vista}}<br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.olfatto}}
            <br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.gusto}}
          </div>-->
          <ul class="ingr">
            <li :class="isChrome ? 'chrome' : 'no-chrome'">
              {{ item.fermentazione }}
            </li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">{{ item.vista }}</li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">
              {{ item.olfatto }}
            </li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">{{ item.gusto }}</li>
          </ul>
        </div>
        <div id="bar">
          <h3 class="sub-c">
            <span v-if="!isChrome" class="row">&#10132;</span>
            <span v-else>
              <span v-if="!isChrome" class="row">&#10132;</span>
              <span v-else>→</span>
            </span>
            Bar
          </h3>
          <div v-for="plate in bar" :key="plate.lenght">
            <div class="list">
              <div class="plate">{{ plate.titolo }}</div>
              <div class="line"></div>
              <div class="price">{{ plate.prezzo }}€</div>
            </div>
            <div class="ingr">{{ plate.ingredienti }}</div>
          </div>
        </div>
      </div>
      <div class="center pizz">
        <h1 class="title" id="brewpub">Il Brew-pub</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/brewpub-m.jpg" />
        </div>
        <p class="intro-c">
          La Spilleria è il primo birrificio artigianale della Martesana (2012),
          nasce in un luogo storico di Sant’Agata: una cooperativa sociale dove
          la voglia di condivisione e di cambiamento ha spinto i “nostri nonni”
          a dare spazio e forma al loro bisogno di migliorare la realtà che gli
          stava intorno. Dal 1945 luogo di aggregazione sociale e culturale,
          l’allora circolino ospita oggi la Spilleria, non solo un birrificio,
          ma un ristorante, una pizzeria con forno a legna, non solo un pub, ma
          una vera Public House da vivere, come un’estensione della vostra casa.
          <br />
          <br />La Spilleria è un brew-pub dotato di micro-impianto di
          produzione all’interno del locale, dove il birraio Mido (al secolo
          Andrea Mandelli, classe 1991) cuoce birra da quando aveva 20 anni.
          Diplomato all’Università della Birra di Azzate con Franco Re, ha
          studiato e prodotto oltre 30 tipologie di birre, diretto serate di
          degustazione e abbinamento di cibo e birra con Slow Food, ed è partner
          del progetto del Luppoleto sociale per promuovere la coltivazione del
          luppolo in Italia.
        </p>
      </div>
      <div class="fdp"></div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
const axios = require("axios");

export default {
  name: "Birre",
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      isChrome: "",
      birre: "",
      loading: false,
      bar: ""
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  },

  mounted() {
    axios
      .get("https://spilleria.frb.io/birre.json")
      // .get("http://localhost:8888/cms-spilleria/birre.json")
      .then(response => {
        this.birre = response.data[0];
        this.bar = response.data[1];
      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = true));
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: repeat(3, auto);
  min-height: 100vh;
}
.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  -webkit-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  border-right: 1px solid $color__text-main;
}

.hero-img {
  height: 100vh;
}

ul {
  list-style: none;
  padding-left: 2em;
  line-height: 150%;
}

li {
  padding-bottom: 0.8em;
}

.chrome::before {
  content: "→  ";
  margin-left: -2em;
  padding-right: 1em;
}

.no-chrome::before {
  content: "\2794 ";
  margin-left: -2em;
  padding-right: 1em;
}
.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}
.gib-cont {
  margin-top: 15vh;
  width: 100%;
  position: relative;

  text-align: center;
}
.gib {
  //position: absolute;
  width: 25vw;
  max-width: 350px;
}

.color {
  height: 3.6vw;
  width: 3.6vw;

  margin-right: 1vw;
}

.rist {
  grid-row: 1;
  grid-column: 2;
}

.pizz {
  grid-row: 2;
  grid-column: 2;
  min-height: 120vh;
  max-height: 1400px;
}

.fornit {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1;
  grid-column: 1;
}

.img-pizz {
  grid-row: 2;
  grid-column: 1;
}

.img-fornit {
  grid-row: 3;
  grid-column: 1;
}

h1 {
  font-size: 6vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: white;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 10vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 1vw;
  font-size: 1.2vw;
  display: block;
  line-height: 130%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 10vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vwpx;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    min-height: 100vh;
  }

  .gib {
    width: 40vw;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 15vh;
  }
  .img-main {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }
}
</style>
