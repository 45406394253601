import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Cucina from "../views/cucina-api.vue";
import Contatti from "../views/contatti.vue";
import Storie from "../views/storie.vue";
import Article_1 from "../views/article1.vue";
import Birre from "../views/birre-api.vue";
import Allergeni from "../views/allergeni.vue";
import Asporto from "../views/asporto.vue";
import Storia from "../views/Storia.vue";
import Eventi from "../views/Eventi.vue";
import Progetti from "../views/Progetti.vue";

import UIkit from "uikit";
 
Vue.use(VueRouter, UIkit);

// const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: Home
//   },
//   {
//     path: "/cucina",
//     name: "Cucina",
//     component: Cucina
//   },
//   // {
//   //   path: "/about",
//   //   name: "About",
//   //   // route level code-splitting
//   //   // this generates a separate chunk (about.[hash].js) for this route
//   //   // which is lazy-loaded when the route is visited.
//   //   component: () =>
//   //     import(/* webpackChunkName: "about" */ "../views/About.vue")
//   // }
// ];

// const router = new VueRouter({
//   routes
// });

const router = new VueRouter({
  //mode: "history",
  //base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/cucina",
      name: "Cucina",
      component: Cucina
    },
    {
      path: "/contatti",
      name: "Contatti",
      component: Contatti
    },
    {
      path: "/storie",
      name: "Storie",
      component: Storie
    },
    {
      path: "/article1",
      name: "Article_1",
      component: Article_1
    },
    {
      path: "/birre",
      name: "Birre",
      component: Birre
    },
    {
      path: "/allergeni",
      name: "Allergeni",
      component: Allergeni
    },
    {
      path: "/asporto",
      name: "Asporto",
      component: Asporto
    },
    {
      path: "/persone/:index",
      name: "storia",
      component: Storia
    },
    {
      path: "/eventi/:index",
      name: "Eventi",
      component: Eventi
    },
    {
      path: "/progetti/:index",
      name: "Progetti",
      component: Progetti
    }
  ],

  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
