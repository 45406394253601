<template>
  <div class="cucina">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist" uk-sticky="bottom: #img-pizz">
        <img class="hero-img" src="@/assets/img/ristorante.png" />
        <h3 class="sect">Cucina</h3>
      </div>
      <div
        class="img-main img-pizz"
        id="img-pizz"
        uk-sticky="bottom: #img-fornit"
      >
        <img class="hero-img" src="@/assets/img/pizzeria.jpg" />
        <h3 class="sect">Pizzeria</h3>
      </div>
      <div class="img-main img-fornit" id="img-fornit" uk-sticky="bottom: true">
        <img class="hero-img" src="@/assets/img/fornitori.jpg" />
        <h3 class="sect">Fornitori</h3>
      </div>

      <div class="center rist">
        <h1 class="title">La Cucina della Spilleria</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/ristorante-mob.png" />
        </div>
        <p class="intro-c">
          La Spilleria non ha congelatore nè surgelatore, tutte le pietanze sono
          cucinate espresse da noi con passione e prodotti sempre stagionali, da
          filiera corta e laddove possibile biologici.
          <br /><br />
          <router-link class="link" to="/allergeni"
            >*Consulta l’Elenco allergeni secondo regolamento UE
            1169/2011</router-link
          >
        </p>



        <div v-if="serata">
          <div v-for="serata in serata" :key="serata.titolo">
            
          
          <h3 class="sub-c" v-if="serata.giorno" >
            <span class="row">
              <span v-if="!isChrome" class="row">&#10132;</span
              ><span v-else>→</span></span
            >
            {{ serata.giorno }}
          </h3>
          <div class="sub-c" v-else style="padding-top: 1px"> </div>
          

          <div class="list">
            <div class="plate">{{ serata.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ serata.prezzo }}€</div>
          </div>
          <div
            class="ingr"
            style="float: right; margin-right: 0; text-align: right"
          >
            {{ serata.prenota }}
          </div>
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Menù
        </h3>

        <div v-for="plate in menu" :key="plate.lenght">
          <div class="list">
            <div class="plate">{{ plate.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ plate.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ plate.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Stuzzicherie
        </h3>
        <div v-for="plate in stuz" :key="plate.lenght">
          <div class="list">
            <div class="plate">{{ plate.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ plate.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ plate.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Pucce, Panini, Piadine
        </h3>
        <div v-for="plate in panini" :key="plate.lenght">
          <div class="list">
            <div class="plate">{{ plate.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ plate.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ plate.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Dolci
        </h3>
        <div v-for="plate in dolci" :key="plate.lenght">
          <div class="list">
            <div class="plate">{{ plate.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ plate.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ plate.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c" style="margin-bottom: 10px" id="hamburger">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Hamburgeria
        </h3>

        <div v-for="hb in hamb" :key="hb.lenght">
          <div class="list">
            <div class="plate">{{ hb.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ hb.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ hb.ingredienti }}
          </div>
        </div>
      </div>

      <div class="center pizz">
        <h1 class="title" id="pizza">Il forno della Spilleria</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/pizzeria-m.jpg" />
        </div>
        <p class="intro-c">
          Nel 2017 la Spilleria chiude il locale una settimana e parte per
          Napoli alla ricerca di prodotti, produttori e segreti per portare la
          pizza napoletana in Martesana.
        </p>
        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Pizze
        </h3>
        <h3 class="cosi-sub">*ingredienti extra +0.50/1.00€</h3>

        <div v-for="pizza in pizze" :key="pizza.lenght">
          <div class="list">
            <div class="plate">{{ pizza.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ pizza.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ pizza.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Le facciamo così
        </h3>
        <div class="cosi">Farina petra3 molata a pietra</div>
        <div class="cosi-sub">
          da agricoltura sostenibile e integrata, Molino Quaglia
        </div>

        <div class="cosi">Pomodoro San Marzano DOP Gustarosso</div>
        <div class="cosi-sub">
          dell’agro-sarnese nocerino, Cooperativa Agricola DaniCoop
        </div>

        <div class="cosi">Fiordilatte e mozzarella di bufala campana</div>
        <div class="cosi-sub">della Latteria Sorrentina</div>

        <div class="cosi">Lievitazione tra le 24 e le 32 ore</div>
        <div class="cosi-sub">con passion’e sentiment’</div>

        <div class="cosi">Olio extra vergine di oliva del Garda</div>
        <div class="cosi-sub">del Frantoio Avanzi di Manerba del Garda</div>

        <div class="cosi">Basilico fresco</div>
        <div class="cosi-sub">coltivato da noi!</div>
      </div>
      <div class="center fornit">
        <h1 class="title" id="fornitori">I nostri fornitori</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/fornitori-m.jpg" />
        </div>
        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Olio
        </h3>
        <div class="cosi">Frantoio Avanzi, Manerba del Garda (BS)</div>
        <div class="cosi-sub">
          Dal 1931 il Frantoio della famiglia Avanzi produce olio extravergine
          superiore: le olive vengono lavorate a ciclo continuo e rigorosamente
          a freddo tramite spremitura a pietre o dischi. L’olio del Garda è
          rinomato per la sua delicatezza e il suo gusto leggermente fruttato.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Ortaggi
        </h3>
        <div class="cosi">
          Azienda agricola biologica CorbariBio, Cernusco Sul Naviglio (Milano)
        </div>
        <div class="cosi-sub">
          Antonio Corbari, precursore del biologico in Lombardia, ha da poco
          passato il testimone a 3 ragazzi giovani. CorbariBio è la
          dimostrazione concreta che, se mossi da spirito ambientalista, si
          possono coltivare prodotti genuini e naturali nel rispetto della
          natura.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Uova
        </h3>
        <div class="cosi">
          Azienda agricola biologica Bargero, Carbonate (Como)
        </div>
        <div class="cosi-sub">
          Galline allevate a terra, liberi nei campi. Pollai strutturati per il
          benessere degli animali e alimentazione delle galline totalmente
          biologica.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Carne, Formaggi, Salumi, Miele
        </h3>
        <div class="cosi">
          Cascina Cortenuova azienda biologica, Truccazzano (Milano)
        </div>
        <div class="cosi-sub">
          Azienda famigliare con liera di produzione a ciclo chiuso che parte
          dalla coltivazione dei campi, all'allevamento del bestiame no alla
          trasformazione delle materie prime.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Affettati, salumi, carni
        </h3>
        <div class="cosi">
          Azienda agricola Tito Il Maso dello Speck,Val Di Fiemme (Trento)
        </div>
        <div class="cosi-sub">
          Nel Maso della famiglia Braito, a 1300mt si lavora ancora con metodi
          artigianali e si producono salumi, würstel e carni affumicate portando
          avanti la passione, i saperi e la tradizione del caro vecchio Tito.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Affettati e salumi
        </h3>
        <div class="cosi">Marco D'Oggiono Prosciutti, Oggiono (Lecco)</div>
        <div class="cosi-sub">
          Marco d’Oggiono è un'azienda a conduzione famigliare che produce dal
          1945 salumi eccelsi nel pieno rispetto della tradizione. Nel 1999 il
          prosciutto crudo Marco D'Oggiono è stato riconosciuto Prodotto
          Tradizionale Lombardo.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Formaggi
        </h3>
        <div class="cosi">Bio Caseificio Tomasoni, Gottolengo (Brescia)</div>
        <div class="cosi-sub">
          Il caseificio Tomasoni trasforma in formaggio solo latte proveniente
          da agricoltura biologica. È l'unico produttore di grana padano bio del
          Lombardo-Veneto con una produzione artigianale giornaliera di solo 4
          forme.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Vino
        </h3>
        <div class="cosi">Libera Terra con Altromercato</div>
        <div class="cosi-sub">
          Libera Terra nasce con l’obiettivo di valorizzare i territori liberati
          dalle mafie per ottenere prodotti di alta qualità attraverso metodi
          rispettosi dell’ambiente e della dignità della persona, creando
          cooperative agricole autonome e autosufficienti basate sulla legalità
          e la giustizia sociale.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Caffé
        </h3>
        <div class="cosi">Caffè Malatesta, Ponte Azzone Visconti (Lecco)</div>
        <div class="cosi-sub">
          Torrefazione autogestita fondata sulla proprietà collettiva dei mezzi
          di produzione e sulle decisioni paritarie. Il caffè equosolidale
          proviene da cooperative zapatiste in Chiapas, Guatemala, Uganda,
          Etiopia sostenendo progetti di solidarietà. E la miscela che bevete in
          Spilleria l’abbiamo scelta noi con i Malatesta!
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Farina
        </h3>
        <div class="cosi">Petra La Farina Molino Quaglia (Padova)</div>
        <div class="cosi-sub">
          “La farina è la forma che il mugnaio dà ai cereali, perché diventino
          ingrediente di base delle diete quotidiane dell'umanità intera”. Dal
          1914 il mantra della famiglia di mugnai del Molino padovano li h
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          pomodoro san marzano
        </h3>
        <div class="cosi">Cooperativa Agricola Dani Coop, Sarno (Salerno)</div>
        <div class="cosi-sub">
          Dani Coop nasce nel 1910 dalla famiglia Ruggero. Oggi conta oltre 70
          soci agricoltori, uniti dalla volontà di condividere le proprie
          conoscenze e capacità per coltivare il Pomodoro San Marzano DOP
          seguendo le esperienze tramandate da quattro generazioni.
        </div>

        <h3 class="sub-c for">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Mozzarella di bufala
        </h3>
        <div class="cosi">Latteria Sorrentina, (Napoli)</div>
        <div class="cosi-sub">
          Cinque generazioni di impegno famigliare, la Latteria Sorrentina nasce
          nel 1880. “Cambiano le tecnologie, cambiano le macchine, ma nelle
          nostre mani resta un sapere antico, il nostro prezioso bagaglio di
          storia e tradizioni che ritrovi in ogni prodotto.”
        </div>
      </div>

      <div class="fdp"></div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
const axios = require("axios");

export default {
  name: "Asporto",
  components: {
    Footer,
    Navbar,
    Menu,
  },
  data() {
    return {
      isChrome: "",
      loading: false,
      menu: "",
      pizze: "",
      stuz: "",
      dolci: "",
      hamb: "",
      panini: "",
      serata: "",
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  },
  mounted() {
    axios
      .get("https://spilleria.frb.io/cucina.json")
      // .get("http://localhost:8888/cms-spilleria/cucina.json")
      .then((response) => {
        this.menu = response.data[0];
        this.pizze = response.data[2];
        this.hamb = response.data[1];
        this.dolci = response.data[3];
        this.panini = response.data[4];
        this.stuz = response.data[5];
        this.serata = response.data[6];
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = true));
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: repeat(3, auto);
  min-height: 100vh;
}
.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  -webkit-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  border-right: 1px solid $color__text-main;
}

.hero-img {
  height: 100vh;
}

.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}

.rist {
  grid-row: 1;
  grid-column: 2;
}

.pizz {
  grid-row: 2;
  grid-column: 2;
}

.fornit {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1;
  grid-column: 1;
}

.img-pizz {
  grid-row: 2;
  grid-column: 1;
}

.img-fornit {
  grid-row: 3;
  grid-column: 1;
}

h1 {
  font-size: 6vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: white;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 10vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 0.4vw;
  font-size: 1.2vw;
  display: block;
  max-width: 70%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 1.5vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vw;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    min-height: 100vh;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 15vh;
  }
  .img-main {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }
}
</style>
