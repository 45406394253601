<template>
  <div class="storie">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist desk" uk-sticky="bottom: #img-fornit">
        <img class="hero-img" src="@/assets/img/persone.jpg" />
        <h3 class="sect">Persone</h3>
      </div>
      <div
        class="img-main img-pizz desk"
        id="img-pizz"
        uk-sticky="bottom: #img-fornit"
      >
        <img class="hero-img" src="@/assets/img/eventi.jpg" />
        <h3 class="sect">Eventi</h3>
      </div>
      <div
        class="img-main img-fornit desk"
        id="img-fornit"
        uk-sticky="bottom: #footer"
      >
        <img class="hero-img" src="@/assets/img/progetti.jpg" />
        <h3 class="sect">Progetti</h3>
      </div>

      <div class="img-main img-rist mobi">
        <h3 class="sect">Persone</h3>
      </div>
      <div class="img-main img-pizz mobi" id="img-pizz">
        <h3 class="sect">Eventi</h3>
      </div>
      <div class="img-main img-fornit mobi" id="img-fornit">
        <h3 class="sect">Progetti</h3>
      </div>

      <div class="center persone">
        <h1 class="title">Le Persone</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/persone-m.jpg" />
        </div>
        <div class="block">
          <div v-for="(per, index) in persone" :key="index">
            <router-link
              v-bind="per"
              class="article"
              :to="{ name: 'storia', params: { index } }"
              >{{ per.titolo }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="center eventi" id="eventi">
        <h1 class="title">Gli Eventi</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/eventi-m.jpg" />
        </div>
        <div class="block">
          <div v-for="(eve, index) in eventi" :key="index">
            <router-link
              class="article"
              :to="{ name: 'Eventi', params: { index } }"
              >{{ eve.titolo }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="center contatti" id="progetti">
        <h1 class="title">I Progetti</h1>
        <div class="img-mob">
          <img class="mob-hero" src="@/assets/img/progetti-m.jpg" />
        </div>
        <div class="block">
          <div v-for="(prj, index) in progetti" :key="index">
            <router-link
              class="article"
              :to="{ name: 'Progetti', params: { index } }"
              >{{ prj.titolo }}</router-link
            >
          </div>
        </div>
      </div>
      <div class="fdp"></div>
    </div>

    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
const axios = require("axios");

export default {
  name: "Storie",
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      persone: null,
      eventi: null,
      progetti: null
    };
  },
  mounted() {
    axios
      .get("https://spilleria.frb.io/storie.json")
      // .get("http://localhost:8888/cms-spilleria/birre.json")
      .then(response => {
        this.persone = response.data[0];
        this.eventi = response.data[1];
        this.progetti = response.data[2];
      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = true));
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: repeat(3, auto);
  min-height: 100vh;
}

.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  -webkit-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  border-right: 1px solid $color__text-main;
}

.hero-img {
  height: 100vh;
}

.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}

.persone {
  grid-row: 1;
  grid-column: 2;
}

.eventi {
  grid-row: 2;
  grid-column: 2;
}

.progetti {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1;
  grid-column: 1;
}

.img-pizz {
  grid-row: 2;
  grid-column: 1;
}

.img-fornit {
  grid-row: 3;
  grid-column: 1;
}

h1 {
  font-size: 6vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: white;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 10vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 0.4vw;
  font-size: 1.2vw;
  display: block;
  max-width: 70%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 1.5vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vwpx;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}

.article {
  margin-top: 1.5vw;
  font-size: 2.2vw;
  line-height: 123%;
  border-bottom: 2px solid $color__text-main;
  color: $color__text-main;
  text-decoration: none;
  padding-bottom: 0.5vw;
  display: block;
}

.title {
  margin-bottom: 5vw;
}

.block {
  margin-bottom: 8vw;
}
.mobi {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
  }

  .mobi {
    display: block;
  }
  .desk {
    display: none;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 9vh;
  }
  .img-main {
    height: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }

  .article {
    font-size: 4vw;
    line-height: 123%;
    border-bottom: 1px solid $color__text-main;

    padding-bottom: 0.5vw;
  }

  .title {
    margin-bottom: 5vw;
  }

  .block {
    margin-bottom: 8vw;
  }
}
.img-mob {
  margin-bottom: 5vh;
}
</style>
