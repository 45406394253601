<template>
  <div :class="{ 'nav-cont-home': grid == 0, 'nav-cont-sub': grid >= 1 }">
    <div :class="{ ite: grid == 0, 'ite-s': grid >= 1 }">
      <router-link to="/">
        <img
          v-if="grid == 0"
          class="logo"
          src="@/assets/img/logo_laspilleria.png"
        />
        <img
          v-else-if="grid == 1"
          class="logo-s"
          src="@/assets/img/logo_laspilleria_w.png"
        />
        <img
          v-else-if="grid == 2"
          class="logo-s"
          src="@/assets/img/logo_laspilleria.png"
        />
      </router-link>
    </div>
    <div class="prenota">PRENOTA</div>
    <div :class="{ menu: grid == 0, 'menu-s': grid >= 1 }">
      <div to="/cucina" :class="{ 'main-t': grid == 0, 'main-ts': grid >= 1 }">
        Birrificio
      </div>
      <div :class="{ 'sub-t': grid == 0, 'sub-ts': grid >= 1 }">
        Le birre
        <br />Il brew-pub
      </div>
      <router-link
        to="/cucina"
        :class="{ 'main-t': grid == 0, 'main-ts': grid >= 1 }"
        >Cucina</router-link
      >
      <div :class="{ 'sub-t': grid == 0, 'sub-ts': grid >= 1 }">
        Il ristorante
        <br />La pizzeria <br />I nostri fornitori
      </div>
      <router-link
        to="storie"
        :class="{ 'main-t': grid == 0, 'main-ts': grid >= 1 }"
        >Storie</router-link
      >
      <div :class="{ 'sub-t': grid == 0, 'sub-ts': grid >= 1 }">
        Le persone
        <br />Gli eventi <br />I progetti
      </div>
      <div class="main-t"></div>
      <router-link
        to="contatti"
        :class="{ 'sub-t': grid == 0, 'sub-ts': grid >= 1 }"
        >Contatti</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: {
    grid: String
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.nav-cont-home {
  padding-top: 3vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}
.nav-cont-sub {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}

.nav-cont-con {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 4fr 4fr 2fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
}

.logo-s {
  margin-left: auto;
  margin-right: auto;
  width: 10vw;
}

.ite {
  justify-self: center;
}

.ite-s {
  justify-self: start;
  margin-left: 3vw;
}

.prenota {
  text-decoration: none;
  justify-self: center;
  font-family: $font__stack-primary;
  font-size: 3vw;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1.5px $color__text-main;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  font-family: $font__stack-primary;
  line-height: 130%;
}

.menu-s {
  justify-self: center;
  font-family: $font__stack-primary;
  line-height: 130%;
}

.main-t {
  text-align: right;
  padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-t {
  padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  padding-right: 10px;
}

.main-ts {
  //padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-ts {
  //padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  //padding-right: 10px;
}
</style>
