<template>
  <div
    :class="{
      'nav-cont-flex': grid == -1,
      'nav-cont-home': grid == 0,
      'nav-cont-sub': grid >= 1
    }"
  >
    <div :class="{ ite: grid == 0 || grid == -1, 'ite-s': grid >= 1 }">
      <router-link to="/">
        <img
          v-if="grid == 0 || grid == -1"
          class="logo"
          src="@/assets/img/logo_laspilleria.png"
        />
        <img
          v-else-if="grid == 1"
          class="logo-s"
          src="@/assets/img/logo_laspilleria_w.png"
        />
        <img
          v-else-if="grid == 2"
          class="logo-s"
          src="@/assets/img/logo_laspilleria.png"
        />
      </router-link>
    </div>
    <router-link v-if="delivery" to="/asporto" class="prenota"
      >ASPORTO/<br />DELIVERY</router-link
    >
    <div></div>
  </div>
</template>

<script>
export default {
  name: "Navbar_2",
  props: {
    grid: String
  },
  data() {
    return {
      delivery: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.nav-cont-flex {
  padding-top: 3vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 50;
  align-items: start;
}

.nav-cont-home {
  padding-top: 3vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}
.nav-cont-sub {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}

.nav-cont-con {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 4fr 4fr 2fr;
  position: fixed;
  width: 100%;
  justify-content: start;
  z-index: 150;
  align-items: start;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
}

.logo-s {
  margin-left: auto;
  margin-right: auto;
  width: 10vw;
}

.ite {
  justify-self: center;
}

.ite-s {
  justify-self: start;
  margin-left: 3vw;
}

.prenota {
  justify-self: center;
  font-family: $font__stack-primary;
  font-size: 2.5vw;
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1.5px $color__text-main;
  text-decoration: none;
  line-height: 3vw;
}

.menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  font-family: $font__stack-primary;
  line-height: 130%;
}

.menu-s {
  justify-self: center;
  font-family: $font__stack-primary;
  line-height: 130%;
}

.main-t {
  text-align: right;
  padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-t {
  padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  padding-right: 10px;
}

.main-ts {
  //padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-ts {
  //padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  //padding-right: 10px;
}

.prenota:hover {
  color: $color__text-main;
  -webkit-text-stroke: 0;
}

@media screen and (max-width: 1080px) {
  .prenota {
    -webkit-text-stroke: 0.5px $color__text-main;
  }
}
</style>
