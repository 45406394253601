/* eslint-disable prettier/prettier */
<template>
  <div class="cucina">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist" uk-sticky="bottom: #footer">
        <h3 class="sect">
          Delivery & <br />
          Asporto
        </h3>
      </div>

      <div class="center rist">
        <h1 class="title">Delivery & Asporto</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/ristorante-mob.png" />
        </div>
        <p class="intro-c">
          Asporto e consegna a domicilio solo venerdì, sabato, domenica dalle h 18 alle 21 (ordine minimo per delivery 20€).<br />
          Prenotazione via msg WhatsApp al
          <a href="tel:+393662338461" style="text-decoration:underline"
            >+39 3662338461</a
          >
          <br />
          O telefonando dalle 17.00 in poi al
          <a href="tel:0291640682" style="text-decoration:underline"
            >02 91640682</a
          ><br /><br />

          Consegnamo a: Cassina, Gorgonzola, Vignate, Melzo, Pessano, Bussero,
          Cernusco. Per tutti gli altri paesi scriveteci e capiamo se riusciamo a organizzarci ;)<br />

          Tutti i packaging sono biodegradabili!<br />

          <br /><br />
          La Spilleria non ha congelatore nè surgelatore, tutte le pietanze sono
          cucinate espresse da noi con passione e prodotti sempre stagionali, da
          filiera corta e laddove possibile biologici. <br /><br />
          Birre artigianali in lattina da 33cl (da conservare in frigo!) solo di
          Giuditta – Imperial IPA 7,7%vol. e VF52 – American Lager 5,2%vol.<br />
          1x 4.50€<br />
          6x 25€<br />
          12x 48€<br /><br />
          <router-link class="link" to="/allergeni"
            >*Consulta l’Elenco allergeni secondo regolamento UE
            1169/2011</router-link
          >
        </p>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Menù
        </h3>
        <div v-for="plate in menu" :key="plate.lenght">
          <div class="list">
            <div class="plate">{{ plate.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ plate.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ plate.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c" style="margin-bottom:10px">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span></span
          >
          Hamburgeria
        </h3>
        <h3 class="cosi-sub">
          *patatine vendute separatamente
        </h3>

        <div v-for="hb in hamb" :key="hb.lenght">
          <div class="list">
            <div class="plate">{{ hb.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ hb.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ hb.ingredienti }}
          </div>
        </div>
      </div>

      <div class="center pizz">
        <h1 class="title" id="pizza">Il forno della Spilleria</h1>
        <div>
          <img class="mob-hero" src="@/assets/img/pizzeria-m.jpg" />
        </div>
        <p class="intro-c">
          Nel 2017 la Spilleria chiude il locale una settimana e parte per
          Napoli alla ricerca di prodotti, produttori e segreti per portare la
          pizza napoletana in Martesana.
        </p>
        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Pizze
        </h3>
        <h3 class="cosi-sub">
          *ingredienti extra +0.50/1.00€
        </h3>

        <div v-for="pizza in pizze" :key="pizza.lenght">
          <div class="list">
            <div class="plate">{{ pizza.titolo }}</div>
            <div class="line"></div>
            <div class="price">{{ pizza.prezzo }}€</div>
          </div>
          <div class="ingr">
            {{ pizza.ingredienti }}
          </div>
        </div>

        <h3 class="sub-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span> Le facciamo così
        </h3>
        <div class="cosi">Farina petra3 molata a pietra</div>
        <div class="cosi-sub">
          da agricoltura sostenibile e integrata, Molino Quaglia
        </div>

        <div class="cosi">Pomodoro San Marzano DOP Gustarosso</div>
        <div class="cosi-sub">
          dell’agro-sarnese nocerino, Cooperativa Agricola DaniCoop
        </div>

        <div class="cosi">Fiordilatte e mozzarella di bufala campana</div>
        <div class="cosi-sub">della Latteria Sorrentina</div>

        <div class="cosi">Lievitazione tra le 24 e le 32 ore</div>
        <div class="cosi-sub">con passion’e sentiment’</div>

        <div class="cosi">Olio extra vergine di oliva del Garda</div>
        <div class="cosi-sub">del Frantoio Avanzi di Manerba del Garda</div>

        <div class="cosi">Basilico fresco</div>
        <div class="cosi-sub">coltivato da noi!</div>
      </div>

      <div class="center fornit">
        <h1 class="title" id="pizza">Le Birre</h1>
        <div v-for="item in birre" :key="item.length">
          <div class="gib-cont">
            <!-- <img class="gib" :src="'/img/' + item.nome.toLowerCase().replace(/ +/g, '') + '.jpg'" /> -->
            <img class="gib" :src="item.gibollo" />
          </div>
          <div class="list">
            <div class="color" :style="'background:' + item.colore"></div>
            <div class="plate">
              {{ item.nome }}
              <br />
              {{ item.tipologia }}
            </div>
            <div class="line"></div>
            <div class="price">{{ item.gradazione }}%vol</div>
          </div>
          <!-- <div class="ingr">
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.fermentazione}}<br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.vista}}<br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.olfatto}}
            <br />
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span>{{item.gusto}}
          </div>-->
          <ul class="ingr">
            <li :class="isChrome ? 'chrome' : 'no-chrome'">
              {{ item.fermentazione }}
            </li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">{{ item.vista }}</li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">
              {{ item.olfatto }}
            </li>
            <li :class="isChrome ? 'chrome' : 'no-chrome'">{{ item.gusto }}</li>
          </ul>
        </div>
      </div>

      <div class="fdp"></div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
const axios = require("axios");

export default {
  name: "Asporto",
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      isChrome: "",
      loading: false,
      menu: "",
      pizze: "",

      birre: "",
      hamb: ""
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  },
  mounted() {
    axios
      .get("https://spilleria.frb.io/asporto.json")
      .then(response => {
        this.menu = response.data[0];
        this.pizze = response.data[2];
        this.hamb = response.data[1];
        this.birre = response.data[3];
      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = true));
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

a {
  color: $color__text-main;
}
.gib-cont {
  margin-top: 15vh;
  width: 100%;
  position: relative;

  text-align: center;
}
.gib {
  //position: absolute;
  width: 25vw;
  max-width: 350px;
}

ul {
  list-style: none;
  padding-left: 2em;
  line-height: 150%;
}

li {
  padding-bottom: 0.8em;
}

.chrome::before {
  content: "→  ";
  margin-left: -2em;
  padding-right: 1em;
}

.no-chrome::before {
  content: "\2794 ";
  margin-left: -2em;
  padding-right: 1em;
}

.color {
  height: 3.6vw;
  width: 3.6vw;

  margin-right: 1vw;
}

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-rows: repeat(3, auto);
  min-height: 100vh;
}
.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  // -webkit-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  // -moz-box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  // box-shadow: 0px -9px 39px -3px rgba(0, 0, 0, 0.33);
  border-right: 1px solid $color__text-main;
}

.hero-img {
  height: 100vh;
}

.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}

.rist {
  grid-row: 1;
  grid-column: 2;
}

.pizz {
  grid-row: 2;
  grid-column: 2;
}

.fornit {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1;
  grid-column: 1/3;
}

// .img-pizz {
//   grid-row: 2;
//   grid-column: 1;
// }

// .img-fornit {
//   grid-row: 3;
//   grid-column: 1;
// }

h1 {
  font-size: 6vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: $color__text-main;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 10vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 0.4vw;
  font-size: 1.2vw;
  display: block;
  max-width: 70%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 1.5vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vwpx;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    min-height: 100vh;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    //background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 15vh;
  }
  .img-main {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }
}
</style>
