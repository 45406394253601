<template>
  <div id="app">
    <router-view />
        <marquee-text class="marquee" :repeat="4" :duration="5">
  {{ marq_text }}
</marquee-text>
    <marquee-text class="marquee_mob" :repeat="4" :duration="3">
  {{ marq_text }}
</marquee-text>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'

const axios = require("axios");
export default {
    components: {

    MarqueeText
  },
  data() {
    return {
      marq_text: null
    }
  },
  created(){
        axios
      .get("https://spilleria.frb.io/info.json")
      // .get("http://localhost:8888/cms-spilleria/info.json")
      .then((response) => {
        this.marq_text = response.data[1].strip_led;
        this.loading = true;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
  }
}
</script>

<style lang="scss">
@import "@/assets/global_styles/typography/typography.scss";

body * {
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering: optimizeLegibility !important;
}

body {
  margin: 0;
  font-weight: 400 !important;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

 a{
   color: white;
 }

 .marquee{
  position: fixed;
  bottom: 0;
  font-family: $font__stack-primary;
  color: white;
  background: $color__text-main;
  font-size: 2em;
  z-index: 99999;

  div{
    padding: 0.2em 1em 0.2em 1em;
  }

  @media screen and (max-width: 1080px) {
    display: none
  }
}
.marquee_mob{
  position: fixed;
  bottom: 0;
  font-family: $font__stack-primary;
  color: white;
  background: $color__text-main;
  font-size: 1.5em;
  z-index: 99999;

  div{
    padding: 0.2em 1em 0.2em 1em;
  }

  @media screen and (min-width: 1080px) {
    display: none
  }
}
.intro-c{
  white-space: pre-line;
}
</style>
