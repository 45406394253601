<template>
  <div class="home">
    <Menu grid="-1" />
    <Navbar grid="-1" />

    <div class="hero-cont">
      <div class="img-cont">
        <img
          class="hero-img"
          src="@/assets/img/home.jpg"
          uk-parallax="y: 0,-100; "
        />
      </div>
      <div id="hero">
        <h1 class="scrim" uk-parallax="y: 0,100;">
          La Spilleria &egrave;
          <router-link class="home-link" to="/birre"
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span> birra artigianale </router-link
          ><router-link class="home-link" to="/cucina">
            <span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span> ristorante, una pizzeria </router-link
          ><router-link class="home-link" to="/storie"
            ><span v-if="!isChrome" class="row">&#10132;</span
            ><span v-else>→</span> e un locale con tante storie
            dentro</router-link
          >
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>

    <Footer id="footer" />
    <!-- <marquee-text class="marquee" :repeat="4" :duration="5">
  {{ marq_text }}
</marquee-text>
    <marquee-text class="marquee_mob" :repeat="4" :duration="3">
  {{ marq_text }}
</marquee-text> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";
// import MarqueeText from 'vue-marquee-text-component'

// const axios = require("axios");

export default {
  name: "Home",
  components: {
    Footer,
    Navbar,
    Menu
    // MarqueeText
  },
  data() {
    return {
      isChrome: "",
      loading: false
      // marq_text: null
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  },
  mounted() {
    // this.loading = false;
    // axios
    //   .get("https://spilleria.frb.io/info.json")
    //   // .get("http://localhost:8888/cms-spilleria/info.json")
    //   .then((response) => {
    //     this.marq_text = response.data[1].strip_led;
    //     this.loading = true;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     this.errored = true;
    //   })
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

h1 {
  font-weight: 400 !important;
  //background: green;
  //font-size: $font__size-xxl;
  font-family: $font__stack-primary;
  color: white;
  -webkit-text-stroke: 0.1vw $color__text-main;
  font-size: 7.5vh;
  letter-spacing: -0.01em;
  line-height: 114%;
}

#hero {
  padding-left: 4vw;
  padding-right: 4vw;
  width: 90%;
  margin: auto;
  position: relative;
}

.hero-cont {
  margin-top: 30vw;
  position: absolute;
}

.container {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100vh;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-cont {
  width: 100%;
  position: absolute;
  //z-index: -100;
  height: 100%;
}

.hero-img {
  position: relative;
  display: block;
  // margin-left: auto;
  // margin-right: auto;
  margin: auto;
  width: 50%;
  bottom: 10vw;

  // opacity: 0.5;
}
.home-link {
  color: white;
  text-decoration: none;
  -webkit-text-stroke: 0.2vh $color__text-main;
}

.home-link:hover {
  color: $color__text-main;
  text-decoration: underline;
  -webkit-text-stroke: 0;
}

.marquee {
  position: fixed;
  bottom: 0;
  font-family: $font__stack-primary;
  color: white;
  background: $color__text-main;
  font-size: 2em;
  z-index: 99999;

  div {
    padding: 0.2em 1em 0.2em 1em;
  }

  @media screen and (max-width: 1080px) {
    display: none;
  }
}
.marquee_mob {
  position: fixed;
  bottom: 0;
  font-family: $font__stack-primary;
  color: white;
  background: $color__text-main;
  font-size: 1.5em;
  z-index: 99999;

  div {
    padding: 0.2em 1em 0.2em 1em;
  }

  @media screen and (min-width: 1080px) {
    display: none;
  }
}

@media screen and (max-width: 1080px) {
  .hero-img {
    position: relative;
    display: block;
    // margin-left: auto;
    // margin-right: auto;
    margin: auto;
    width: 90%;
    margin-top: 20vh;
    //bottom:-10vw

    //opacity: 0.5;
  }

  h1 {
    -webkit-text-stroke: 0.2vw $color__text-main;
    font-size: 6vh;
  }

  .home-link {
    -webkit-text-stroke: 0.2vw $color__text-main;
  }
}

.scrim {
  border-radius: 70%;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 9rem rgba(255, 255, 255, 0.7);
}

.row {
  -webkit-text-stroke: 0.1vw $color__text-main;
}
</style>
