<template>
  <div class="birre">
    <Navbar grid="2" />
    <Menu grid="1" />
    <div class="container">
      <div class="img-main img-rist" uk-sticky="bottom: true">
        <h3 class="sect">Allergeni</h3>
      </div>

      <div class="center rist">
        <h1 class="title">
          Sostanze o prodotti che provocano allergie o intolleranze
        </h1>

        <p class="cosi">
          Allegato II del regolamento UE 1169/2011
        </p>
        <p class="intro-c">
          Nel 2017 la Spilleria chiude il locale una settimana e parte per
          Napoli alla ricerca di prodotti, produttori e segreti per portare la
          pizza napoletana in Martesana.
        </p>
        <p class="intro-c">
          1 cereali contenenti glutine e derivati (grano, segale, orzo, avena,
          farro, kamut) <br />
          pasta e/o cereale di stagione, birra, pane, pizza, panini, piadine,
          hamburger, pollo fritto
        </p>
        <p class="intro-c">
          2 crostacei e prodotti a base di crostacei <br />
          fregola/paella (chef consiglia periodico)
        </p>
        <p class="intro-c">
          4 pesce e prodotti a base di pesce <br />
          fregola/paella (chef consiglia periodico), cozze (chef consiglia del
          giovedì)
        </p>
        <p class="intro-c">
          5 arachidi e prodotti a base di arachidi
        </p>
        <p class="intro-c">
          6 soia e prodotti a base di soia <br />
          hamburger Veggy burger
        </p>
        <p class="intro-c">
          7 latte e prodotti a base di latte e lattosio <br />
          taglieri di formaggi, beercake, birramisu, torta di mele, cheescake,
          tutti hamburger e panini e piadine, tagliata,
        </p>
        <p class="intro-c">
          8 frutta a guscio e loro prodotti <br />
          mandorle, nocciole, noci, noci di Acagiù, noci Pecan, noci del
          Brasile, pistacchi, noci macadamia, noci del Queensland
        </p>
        <p class="intro-c">
          9 sedano e prodotti a base di sedano
        </p>
        <p class="intro-c">
          10 senape e prodotti a base di senape <br />
          salsa senape&miele, hamburger “Big Family”,
        </p>
        <p class="intro-c">
          11 semi di sesamo e prodotti a base di sesamo <br />
          tutti gli hamburger
        </p>
        <p class="intro-c">
          12 anidride solforosa e solfiti in concentrazioni superiori a 10mg/kg
        </p>
        <p class="intro-c">
          13 lupini e prodotti a base di lupini
        </p>
        <p class="intro-c">
          14 molluschi e prodotti a base di molluschi <br />
          fregola (chef consiglia del sabato)
        </p>

        <p class="intro-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span>Il nostro locale non è certificato AiC
          (associazione italiana celiachia). <br />
          La cucina è un ambiente unico in cui quotidianamente vengono lavorati
          sfarinati o altri prodotti contenenti sostanze che potrebbero causare
          allergie o intolleranze, pertanto non possiamo garantire la non
          contaminazione dei piatti.
        </p>

        <p class="intro-c">
          <span v-if="!isChrome" class="row">&#10132;</span
          ><span v-else>→</span>Se soffrite di allergie o intolleranze
          alimentari rivolgetevi al nostro personale di servizio che è a vostra
          disposizione per aiutarvi nella scelta dei piatti e per fornire ogni
          informazione sulla scelta delle pietanze circa la presenza di sostanze
          che possono provocare allergie e intolleranze anche mediante la
          consultazione di apposita documentazione scritta (quali istruzioni
          operative, ricettari o etichette).
        </p>
      </div>

      <div class="fdp"></div>
    </div>
    <Footer id="footer" />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar_2";
import Menu from "@/components/menu";

export default {
  name: "Allergeni",
  components: {
    Footer,
    Navbar,
    Menu
  },
  data() {
    return {
      isChrome: ""
    };
  },
  created() {
    var sUsrAg = navigator.userAgent;

    this.isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (sUsrAg.indexOf("Chrome") > -1) {
      this.isChrome = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  font-weight: 400 !important;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;

  min-height: 100vh;
}
.fdp {
  display: block;
  grid-row: 1 / end;
  grid-column: 3;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.hero-img {
  height: 100vh;
}

.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}
.gib-cont {
  margin-top: 15vh;
  width: 100%;
  position: relative;

  text-align: center;
}
.gib {
  //position: absolute;
  width: 25vw;
  max-width: 350px;
}

.color {
  height: 3.6vw;
  width: 3.6vw;

  margin-right: 1vw;
}

.rist {
  grid-row: 1;
  grid-column: 2;
}

.pizz {
  grid-row: 2;
  grid-column: 2;
}

.fornit {
  grid-row: 3;
  grid-column: 2;
}

.img-rist {
  grid-row: 1;
  grid-column: 1;
}

.img-pizz {
  grid-row: 2;
  grid-column: 1;
}

.img-fornit {
  grid-row: 3;
  grid-column: 1;
}

h1 {
  font-size: 6vw;
  font-weight: 400;
}

h3 {
  font-weight: 400;
}

.sect {
  color: $color__text-main;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: 3vh;
  left: 2.5vw;
  font-size: 5vw;
  margin-bottom: 0;
}

.intro-c {
  margin-top: 3vw;
  font-size: 18px;
  line-height: 123%;
}

.sub-c {
  margin-top: 5vw;
  font-size: 3vw;
  line-height: 123%;
}

.list {
  margin-top: 2vw;
  display: flex;
  justify-content: space-between;
}

.plate {
  font-size: 1.8vw;
  max-width: 70%;
}

.price {
  align-self: flex-end;
  font-size: 1.8vw;
  text-align: right;
}
.line {
  margin: 0 5px 5px 5px;
  border-bottom: 2px solid $color__text-main;
  flex-grow: 1;
}

.ingr {
  margin-top: 1vw;
  font-size: 1.2vw;
  display: block;
  line-height: 130%;
}

.right {
  padding-left: 30%;
  text-align: right;
  max-width: 70% !important;
  clear: both;
}

.sub-sub {
  margin-top: 0;
  font-size: 22px;
}

.cosi {
  margin-top: 10vw;
  font-size: 1.8vw;
}

.cosi-sub {
  margin-top: 2vwpx;
  font-size: 1.2vw;
  display: block;
  line-height: 120%;
}

.for {
  margin-bottom: 0;
}

.link {
  color: $color__text-main;
}

.mob-hero {
  display: none;
}
@media screen and (max-width: 1080px) {
  .container {
    font-weight: 400 !important;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    min-height: 100vh;
  }

  h1 {
    font-size: 30px;
  }

  .sub-c {
    font-size: 24px;
  }

  .price {
    font-size: 15px;
  }

  .plate {
    font-size: 15px;
  }

  .ingr {
    font-size: 10px;
  }

  .sub-c {
    margin-top: 15vw;
  }

  #pizza {
    margin-top: 20vw;
  }

  #fornitori {
    margin-top: 20vw;
  }

  .cosi {
    margin-top: 4vw;
    font-size: 15px;
  }

  .cosi-sub {
    margin-top: 2px;
    font-size: 12px;
  }

  .mob-hero {
    display: block;
    width: 56vw;
  }

  .line {
    margin: 0 3px 2px 3px;
    border-bottom: 1px solid $color__text-main;
  }
  .hero-img {
    display: none;
  }

  .sect {
    background-color: white;
    color: $color__text-main;
    font-size: 3vw;
    bottom: 15vh;
  }
  .img-main {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-right: 0 solid $color__text-main;
  }
}
</style>
