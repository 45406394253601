<template>
  <div class="contain">
    <div class="footer-social">
      <ul>
        <li>
          <a href="https://www.facebook.com/LaSpilleria">Facebook</a>
        </li>

        <li>
          <a href="http://instagram.com/la_spilleria/">Instagram</a>
        </li>

        <li>
          <a href="https://www.youtube.com/channel/UCrf9G5c2uHR1Kwh1Tm-FDzg"
            >YouTube</a
          >
        </li>

        <li>
          <a
            href="https://open.spotify.com/user/la_spilleria?si=a0VgcZWRT0O7iKLhluZvHg"
            >Spotify</a
          >
        </li>

        <!-- <li>
          <div v-on:click="news">Newsletter</div>
        </li> -->

        <li>
          <router-link to="Contatti">Contatti</router-link>
        </li>
      </ul>
    </div>
    <!-- <div class="item voice">
      <p id="orari">Orari</p>
    </div>
    <div class="item orar">
      <div class="footer-info__detail">
        Lun - Gio
        <span>18:00 - 01:00</span>
        <br />Ven - Sab
        <span>18:00 - 02:00</span>
        <br />Dom
        <span>&#x2014;</span>
      </div>
    </div> -->

    <div class="item voice">
      <div id="orari">
        <p style="margin-bottom: 0.2em">
          {{ titolo }}
          <br />
        </p>
        <div class="pre-sic" v-html="subtitolo"></div>
      </div>
    </div>
    <div class="item orar">
      <div class="footer-info__detail">
        <div>
          <div v-if="loading">
            <div v-for="tim in orari" :key="tim.length">
              {{ tim.giorni }} : <br />
              {{ tim.orari }}
            </div>
          </div>
          <br />
          <!-- Asporto e consegna a domicilio solo venerdì sabato e domenica, dalle
          18.30 alle 21.30<br /><br />
          Telefonando dalle 18.00 in poi al
          <a href="tel:0291640682" style="text-decoration: underline"
            >02 91640682</a
          ><br />
          Tramite msg WhatsApp
          <a href="tel:+393662338461" style="text-decoration: underline"
            >+39 3662338461</a
          ><br /> -->
          <div v-if="loading" v-html="info_orari.info_orari"></div>
        </div>
      </div>
    </div>
    <div class="item voice">
      <p id="Tind">Indirizzo</p>
    </div>
    <div class="item ind">
      <div class="footer-info__detail">
        Largo Cooperativa, 4 <br />
        Sant’Agata Martesana frazione di Cassina de’ Pecchi, Milano
      </div>
    </div>
    <div class="item voice">
      <p id="Ttel">Tel</p>
    </div>
    <div class="item tel">
      <div class="footer-info__detail">
        <a href="tel:0291640682">02 91640682</a><br />
        (solo dalle 18 in poi)<br />
        <a href="tel:+393662338461">+39 3662338461</a><br />(solo Whatsapp)
      </div>
    </div>
    <div class="item voice">
      <p id="Temail">Email</p>
      <p class="discl">*non per prenotazioni, non le leggiamo in tempo</p>
    </div>
    <div class="item email">
      <div class="footer-info__detail">
        <a href="mailto:info@laspilleria.it">info@laspilleria.it</a>
        <br />non per prenotazioni<br />

        <a href="mailto:mandellifederica@gmail.com"
          >mandellifederica@gmail.com</a
        ><br />Per cene speciali, eventi, progetti, storie
      </div>
    </div>
    <div class="fill"></div>
    <div class="logo-foo">
      <div class="log-cont-f">
        <img id="log-f" src="@/assets/img/logo_laspilleria_w.png" />
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "Footer",
  data() {
    return {
      orari: null,
      titolo: null,
      subtitolo: null,
      info_orari: null,
      loading: false
    };
  },

  methods: {
    // pd(){
    //   var k = Math.floor(Math.random() * 3)
    //   if(k == 0){
    //     console.log("%c PORCADDIO", "color:pink");
    //   } else if (k == 1) {
    //     console.log("%c MADONNALAIDA", "color:orange");
    //   } else {
    //     console.log("%c FANCULO", "color:red");
    //   }
    // },
    // daje(){
    //  console.log("%c DAJE", "color:green; font-weight:bold" );
    // }
  },

  mounted() {
    this.loading = false;
    axios
      .get("https://spilleria.frb.io/info.json")
      // .get("http://localhost:8888/cms-spilleria/info.json")
      .then(response => {
        this.orari = response.data[0];
        this.titolo = response.data[2].title_footer;
        this.subtitolo = response.data[3].subtitle_footer;
        this.info_orari = response.data[4];
        this.loading = true;
      })
      .catch(error => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = true));
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";
a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  color: white !important;
}
a:-webkit-any-link {
  color: white !important;
}

.sic {
  font-size: 1em;
  text-decoration: underline;
  margin-top: 0;
}

.pre-sic {
  margin-top: 0;
  font-size: 0.5em;
  line-height: 1.2em !important;
}

.contain {
  position: relative;
  z-index: 250;
  display: grid;
  background: $color__text-main;
  //min-height: 100vh;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(5, auto);
  grid-template-areas:
    "social Torari orari"
    "social Tind ind"
    "social Ttel tel"
    "social Temail email"
    "social logo email ";
}

.contain > div {
  border-left: 1px solid white;
}

ul {
  list-style-type: none;
  padding-left: 3vw;
}

.footer-social {
  grid-area: social;
  font-family: $font__stack-primary;
  color: white;
  font-size: 6vw;
  line-height: 140%;
}

a {
  color: white;
  text-decoration: none;
}

.voice {
  font-family: $font__stack-primary;
  color: white;
  font-size: 4vw;
  line-height: 120%;
  text-align: right;
}

#orari {
  margin-block-start: 6vw;
  padding-right: 3vw;
  padding-left: 3vw;
  grid-area: Torari;

  @media screen and (max-width: 1080px) {
    padding-left: 0;
    padding-bottom: 30px;
  }
}

.footer-info__detail {
  font-family: $font__stack-primary;
  color: white;
  font-size: 2vw;
  line-height: 150%;
  padding-left: 2vw;
  padding-right: 1vw;
  margin-block-start: 7vw;
}

span {
  margin-left: 10%;
}

.orar {
  grid-area: orari;
}

.ind {
  grid-area: ind;
}

#Tind {
  grid-area: Tind;
  margin-block-start: 6vw;
  padding-right: 3vw;
}

.tel {
  grid-area: tel;
}

#Ttel {
  grid-area: Ttel;
  margin-block-start: 6vw;
  padding-right: 3vw;
}

.email {
  grid-area: email;
}

.footer-info__detail > a {
  text-decoration: underline;
}

#Temail {
  grid-area: Temail;
  margin-block-start: 6vw;
  padding-right: 0.8em;
  margin-bottom: 0;
}

.logo-foo {
  padding-top: 150px;
  padding-bottom: 30px;
  grid-area: logo;
  //place-self: center;
}

#log-f {
  display: block;
  margin: auto;
  width: 15vw;
  padding-bottom: 100px;
}

.discl {
  margin-top: 0;
  font-size: 2vw;
  line-height: 100%;
  padding-right: 3vw;
}

.fill {
  display: none;
}

a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1080px) {
  .fill {
    display: block;
    height: 100%;
    grid-area: fill;
  }
  .contain {
    display: grid;
    background: $color__text-main;
    //min-height: 100vh;
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-rows: repeat(9, auto);
    grid-template-areas:
      "social Torari fill"
      "social orari fill"
      "social Tind fill"
      "social ind fill"
      "social Ttel fill"
      "social tel fill"
      "social Temail fill"
      "social email fill"
      "social logo fill ";
  }

  .contain > div {
    text-align: left !important;

    padding-left: 3vw;
  }

  .footer-info__detail {
    padding-left: 0;
    margin-block-start: 1vw;
    font-size: 3vw;
    padding-right: 2vw;
  }
  .footer-social {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: 4.9vw;
    line-height: 160%;
  }
  .voice > p {
    font-size: 6vw;
    margin-block-end: 2vw;
    line-height: 120%;
  }

  .discl {
    margin-top: 7px;
    font-size: 3.5vw !important;
  }

  ul {
    padding-left: 0;
  }
}
</style>
