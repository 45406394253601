<template>
  <div class="article1">
    <Navbar grid="1" />
    <div class="container">
      <div class="img-main">
        <img class="hero-img" src="@/assets/img/luogo1.png" />
        <h3 class="sect">Storie</h3>
      </div>
      <div class="center">
        <h1 class="title">CRF – La Spilleria prima della Spilleria</h1>
        <p>
          Circolo Ricreativo Famigliare, ecco cos’è quel CRF sulle finestre. Le
          lettere sono state forgiate a mano da chi il circolino di Sant’Agata
          l’ha voluto, creato mattone su mattone e animato per anni. Dal 1945,
          quando l’Italia dilaniata dal fascismo aveva bisogno di essere
          ricostruita su tutti i piani, soprattutto quello sociale. E così fino
          agli anni 90’ l’ei fu Spilleria era una casa del popolo, il circolino
          punto di riferimento per tutto il paese, primo luogo dove arrivò la
          TV, posto di bevute, mangiate, chiacchiere, balli in balera e partite
          di biliardo.
        </p>
        <div class="carusel">
          <img class="img-view" src="@/assets/img/luogo1.png" />
          <router-link to="storie" class="back">← Indietro</router-link>
        </div>
      </div>
      <div></div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar";
export default {
  name: "article_1",
  components: {
    Footer,
    Navbar
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

.container {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  grid-template-row: repeat(3, auto);
  min-height: 100vh;
}

.container > div {
  border-left: 1px solid $color__text-main;
}

.img-main {
  overflow: hidden;
}

.hero-img {
  height: 100vh;
  margin-left: -50%;
}

.center {
  padding: 10vw 5vw;
  font-family: $font__stack-primary;
  color: $color__text-main;
}

h1 {
  font-size: 6vw;
}

.sect {
  color: white;
  font-family: $font__stack-primary;
  position: absolute;
  bottom: -5vw;
  left: 2.5vw;
  font-size: 5vw;
}

.title {
  margin-bottom: 5vw;
  font-size: 3.5vw;
}

p {
  line-height: 123%;
  font-size: 1.4vw;
}

.img-view {
  width: 100%;
}

.carusel {
  margin-top: 5vw;
}

.back {
  text-align: center;
  display: block;
  color: $color__text-main;
  text-decoration: none;
  margin-top: 5vw;
  font-size: 1.8vw;
}
</style>
