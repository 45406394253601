<template>
  <div>
    <div>
      <div
        class="hammenu"
        id="nav-icon4"
        v-bind:class="{ open: hammenu, 'ham-s': grid >= 1 }"
        @click="toggleMenu"
      >
        <span></span><span></span><span></span>
      </div>
    </div>
    <div :class="{ menu: grid == 0 || grid == -1, 'menu-s': grid >= 1 }">
      <router-link
        to="/birre"
        :class="{ 'main-t': grid == 0 || grid == -1, 'main-ts': grid >= 1 }"
      >
        Bere
      </router-link>
      <div :class="{ 'sub-t': grid == 0 || grid == -1, 'sub-ts': grid >= 1 }">
        <router-link to="/birre">Le birre</router-link>
        <br /><router-link to="birre#bar">Il bar</router-link>
        <br /><router-link to="/birre#brewpub">Il brew-pub</router-link>
      </div>
      <router-link
        to="/cucina"
        :class="{ 'main-t': grid == 0 || grid == -1, 'main-ts': grid >= 1 }"
        >Mangiare</router-link
      >
      <div :class="{ 'sub-t': grid == 0 || grid == -1, 'sub-ts': grid >= 1 }">
        <router-link to="/cucina">Il ristorante</router-link>
        <br /><anchor-router-link
          :to="{ name: 'Cucina', hash: '#hamburger' }"
          :scrollOptions="scrollOptions"
          >Gli hamburger</anchor-router-link
        >
        <br /><router-link to="/cucina#pizza">La pizzeria</router-link>
        <!-- <br /><router-link to="/cucina#fornitori"
          >I nostri fornitori</router-link
        > -->
      </div>
      <router-link
        to="/storie"
        :class="{ 'main-t': grid == 0 || grid == -1, 'main-ts': grid >= 1 }"
        >Storie</router-link
      >
      <div :class="{ 'sub-t': grid == 0 || grid == -1, 'sub-ts': grid >= 1 }">
        <router-link to="/storie">Le persone</router-link>
        <br />
        <router-link to="/storie#eventi">Gli eventi</router-link>
        <br /><router-link to="/storie#progetti">I progetti</router-link>
      </div>
      <div class="main-t"></div>
      <router-link
        to="contatti"
        :class="{ 'sub-t': grid == 0 || grid == -1, 'sub-ts': grid >= 1 }"
        >Contatti</router-link
      >
    </div>
    <div v-if="hammenu" class="mob-menu ">
      <router-link to="/birre" class="mob-itemns mob-main">Bere</router-link>
      <div class="mob-itemns mob-sub">
        <router-link to="/birre">Le birre</router-link> <br /><router-link
          to="/birre#bar"
          >Il bar</router-link
        >
        <br /><router-link to="/birre#brepub">Il brew-pub</router-link>
      </div>
      <div class="mob-itemns"></div>
      <router-link to="/cucina" class="mob-itemns mob-main">
        Mangiare
      </router-link>
      <div class="mob-itemns mob-sub">
        <router-link to="/cucina">Il ristorante</router-link> <br />
        <anchor-router-link
          :to="{ name: 'Cucina', hash: '#hamburger', offset: -1000 }"
          :scrollOptions="scrollOptions"
          >Gli hamburger</anchor-router-link
        >
        <br />
        <router-link to="/cucina#pizza">La pizzeria</router-link
        ><br /><router-link to="/cucina#fornitori"
          >I nostri fornitori</router-link
        >
      </div>
      <div class="mob-itemns"></div>
      <router-link to="/storie" class="mob-itemns mob-main">
        Storie
      </router-link>
      <div class="mob-itemns mob-sub">
        <router-link to="/storie">Le persone</router-link> <br /><router-link
          to="/storie#eventi"
          >Gli eventi</router-link
        ><br /><router-link to="/storie#progetti">I progetti</router-link>
      </div>
      <div class="mob-itemns"></div>
      <div class="mob-itemns"></div>
      <router-link to="/contatti" class="mob-itemns mob-sub">
        Contatti
      </router-link>
      <div class="mob-itemns"></div>
    </div>
  </div>
</template>

<script>
//var VueScrollTo = require('vue-scrollto');
import AnchorRouterLink from "vue-anchor-router-link";
export default {
  name: "Menu",
  components: {
    AnchorRouterLink
  },
  data() {
    return {
      hammenu: false,
      scrollOptions: {
        container: "body",
        duration: 700,
        easing: "ease",
        offset: 0,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
      }
    };
  },
  props: {
    grid: String
  },
  methods: {
    toggleMenu: function() {
      this.hammenu = !this.hammenu;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/global_styles/typography/typography.scss";

a:hover {
  text-decoration: underline !important;
}
.mob-menu {
  width: 100vw;
  height: 100%;
  background: $color__text-main;
  position: fixed;
  z-index: 300;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 20vh 20vh 20vh auto;
  color: white;
  font-family: $font__stack-primary;
}

.mob-menu > .mob-itemns {
  border-left: 1px solid white;
  text-decoration: none;
  padding-top: 5vw;
  line-height: 190%;
}

.mob-sub {
  color: white;
  padding-left: 2vw;
  padding-top: 7.9vw !important;
  font-size: 4vw;
}

.mob-main {
  text-align: right;
  color: $color__text-main;
  -webkit-text-stroke: 0.8px white;
  font-size: 6vw;
  padding-right: 2vw;
}

.hammenu {
  display: none;
}

.menu {
  z-index: 200;
  width: 15vw;
  margin-top: 4vw;
  right: 7vw;
  position: fixed;
  float: right;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(4, auto);
  font-family: $font__stack-primary;
  line-height: 130%;
  font-size: 1.3vw;
}

.menu-s {
  justify-self: center;
  font-family: $font__stack-primary;
  line-height: 150%;
  margin-top: 3vw;
  right: 4vw;
  position: fixed;
  float: right;
  z-index: 200;
}

.main-t {
  text-align: right;
  padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-t {
  padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  padding-right: 10px;
}

.main-ts {
  //padding-right: 10px;
  -webkit-text-stroke: 0.8px $color__text-main;
  color: rgba(255, 255, 255, 0);
}

.sub-ts {
  //padding-left: 10px;
  padding-bottom: 20px;
  color: $color__text-main;
  //padding-right: 10px;
}

@media screen and (max-width: 1080px) {
  .menu {
    display: none;
  }

  .menu-s {
    display: none;
  }

  .hammenu {
    display: block;
    z-index: 220;
    width: 10vw;
    margin-top: 3vw;
    right: 10vw;
    position: fixed;
    float: right;
  }

  .ham-s {
    right: 3vw;
  }
}
#nav-icon4 {
  width: 40px;
  height: 30px;
  position: fixed;
  margin: 5vw auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $color__text-main;

  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon4 span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  background: white;
  top: -2px;
  left: 4px;
}

#nav-icon4.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background: white;
  top: 26px;
  left: 3px;
}

.open {
  z-index: 400;
}

a {
  color: inherit;
  text-decoration: none;
}
</style>
